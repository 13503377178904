import "./notice.css";
import React from "react";

export default function Notice() {
  return (
    <div className="notice">
      <p>
        <span>where everyone's</span>
        <br />
        <span>choice is headed</span>
      </p>
      <p>
        We provide the best service tailored to our customers, increase
        corporate or brand value,
        <br />
        Systematic analysis using big data to meet the rapidly changing
        marketing environment We provide solutions.
      </p>
      <p>
        고객 맞춤형 최상의 서비스를 제공하고 기업또는 브랜드 가치를 높이며
        <br />
        빅데이터를 활용한 체계적인 분석으로 급변하는 마케팅 환경에 맞는
        <br />
        솔루션을 제공합니다.
      </p>
    </div>
  );
}
