import React from "react";
import IdeaBox from "./ideaBox";
import "./idea.css";

export default function Idea() {
  return (
    <div className="idea">
      <p className="idea_title">
        <span>LET'S </span>
        <span>WEB</span>
      </p>
      <div className="idea_wrapper">
        <IdeaBox
          title={["PLAN", "기획"]}
          info={[
            "brand analysis",
            "value derivation",
            "web planning",
            "브랜드 분석",
            "브랜드 가치 도출",
            "웹 기획",
          ]}
        />
        <IdeaBox
          title={["DESIGN", "디자인"]}
          info={[
            "UI/UX",
            "Sensuous design",
            "best quality",
            "UI/UX",
            "감각적인 디자인",
            "최상의 퀄리티",
          ]}
        />
        <IdeaBox
          title={["DEVELOPMENT", "개발"]}
          info={[
            "website",
            "Mobile/App",
            "Responsive Web",
            "웹사이트 개발",
            "모바일/앱",
            "반응형 웹",
          ]}
        />
        <IdeaBox
          title={["MAINTENANCE", "유지보수"]}
          info={[
            "Website management",
            "Hosting/Domain Management",
            "retouch",
            "웹사이트 관리",
            "호스팅/도메인 관리",
            "수정",
          ]}
        />
        <IdeaBox title={["MARKETING", "마케팅"]} info={[]} />
      </div>
    </div>
  );
}
