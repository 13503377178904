import React from "react";
import "./expression.css";
import ExpressionBox from "./expressionBox";
import BLOG_IMG from "../../assets/images/blog.png";
import CAFE_IMG from "../../assets/images/cafe.png";
import NEWS_IMG from "../../assets/images/news.png";
import INSTA_IMG from "../../assets/images/insta.png";
import FACE_IMG from "../../assets/images/face.png";
import SEARCH_IMG from "../../assets/images/search.png";
import Z_IMG from "../../assets/images/z.png";
import NAVER_IMG from "../../assets/images/naver.png";
import Y_IMG from "../../assets/images/y.png";
import P_IMG from "../../assets/images/p.png";

export default function Expression() {
  return (
    <div className="expression">
      <ExpressionBox img={BLOG_IMG} alt="blog_img" title="블로그" />
      <ExpressionBox img={CAFE_IMG} alt="cafe_img" title="카페" />
      <ExpressionBox img={NEWS_IMG} alt="news_img" title="언론 홍보" />
      <ExpressionBox img={INSTA_IMG} alt="insta_img" title="인스타그램" />
      <ExpressionBox img={FACE_IMG} alt="face_img" title="페이스북" />
      <ExpressionBox img={SEARCH_IMG} alt="search_img" title="검색광고" />
      <ExpressionBox img={Z_IMG} alt="z_img" title="지식인" />
      <ExpressionBox img={NAVER_IMG} alt="naver_img" title="네이버쇼핑" />
      <ExpressionBox img={P_IMG} alt="p_img" title="플레이스" />
      <ExpressionBox img={Y_IMG} alt="y_img" title="유튜브" />
    </div>
  );
}
