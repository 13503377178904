import Home from "./pages/home";
import DefaultLayout from "./layout/defaultLayout";

function App() {
  return (
    <DefaultLayout>
      <Home />
    </DefaultLayout>
  );
}

export default App;
