import React from "react";
import Heading from "./heading";
import "./header.css";

export default function Header() {
  return (
    <header className="header">
      <Heading clsn={" header_heading"} />
    </header>
  );
}
