import React from "react";
import MAP_IMG from "../../assets/images/map.png";
import "./map.css";

export default function Map() {
  return (
    <div className="map">
      <div className="map_wrapper">
        <div>
          <img src={MAP_IMG} alt="지도" />
        </div>
        <address className="map_address">
          <span className="map_title">INCHEON</span>
          <span className="map_info">
            인천광역시 연수구 송도과학로 32 테크노IT센터 M동 2202호 /
            주차타워(1시간 무료 및 주차증 제공){" "}
            <span>
              <b id="bold_font">지하, 지상 1, 3, 5층 엘리베이터</b>를 이용하시면
              편리합니다.
            </span>
          </span>
        </address>
      </div>
    </div>
  );
}
