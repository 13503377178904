import React from "react";
import Heading from "./heading";
import "./footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer_wrapper">
        <Heading clsn={" footer_heading"} />
        <address className="address_wrapper">
          <p>인천광역시 연수구 송도과학로 32 테크노IT센터 M동 2202호</p>
          <p>사업자 번호: 149-87-02852</p>
          <p>Tel: 070-7610-6027</p>
          <p>E-mail: admin@let-shack.com</p>
        </address>
      </div>
    </footer>
  );
}
