import React from "react";
import Notice from "./notice";
import MainBanner from "./mainBanner";
import Idea from "./idea";
import Map from "./map";
import Expression from "./expression";
import "./home.css";

export default function Home() {
  return (
    <div className="home">
      <Notice />
      <MainBanner />
      <Idea />
      <div className="b"></div>
      <Expression />
      <Map />
    </div>
  );
}
