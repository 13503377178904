import "./ideaBox.css";
import React, { Fragment } from "react";

export default function IdeaBox({ title = [], info = [] }) {
  return (
    <div className="idea_box">
      <div className="idea_box_title">
        {title.map((i, index) => (
          <span key={index}>{i}</span>
        ))}
      </div>
      <div className="info_box">
        {info.length > 0 &&
          info.map((i, index) =>
            index !== 3 ? (
              <span key={index}>{i}</span>
            ) : (
              <Fragment key={index}>
                <div></div>
                <span>{i}</span>
              </Fragment>
            )
          )}
      </div>
    </div>
  );
}
