import "./expressionBox.css";
import React from "react";

export default function ExpressionBox({
  img = "",
  alt = "NO_IMAGE",
  title = "NO_TITLE",
}) {
  return (
    <div className="expression_box">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img src={img} alt={alt} />
      </div>
      <span>{title}</span>
    </div>
  );
}
