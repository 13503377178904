import React from "react";
import "./defaultLayout.css";
import Footer from "../components/footer";
import Header from "../components/header";

export default function DefaultLayout({ children }) {
  return (
    <div className="default_layout">
      <Header />
      <main className="main_wrapper">{children}</main>
      <Footer />
    </div>
  );
}
