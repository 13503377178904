import "./heading.css";
import React from "react";

export default function Heading({ clsn }) {
  return (
    <h1 className={"lets_web" + clsn}>
      <span>LET'S</span>
      <span> W</span>
      <span>EB</span>
    </h1>
  );
}
