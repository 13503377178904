import React from "react";
import BULB_IMG from "../../assets/images/bulb.png";
import "./mainBanner.css";

export default function MainBanner() {
  return (
    <div className="main_banner">
      <div className="banner_wrapper">
        <div className="banner_box">
          <span>1</span>
          <p>
            Needs Custom
            <br />
            Creation
            <br />
            experience
          </p>
        </div>
        <div className="banner_box">
          <span>2</span>
          <p>
            UI/UX
            <br />
            best quality
            <br />
            thorough analysis
          </p>
        </div>
        <div className="banner_box">
          <span>3</span>
          <p>
            management
            <br />
            consulting
            <br />
            Planing
          </p>
        </div>
        <div className="bulb_img">
          <img src={BULB_IMG} alt="bulb_img" />
        </div>
      </div>
    </div>
  );
}
